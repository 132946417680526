var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('br'),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":_vm.flex,"offset":_vm.offset}},[_c('h1',[_vm._v("Login")]),_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{ref:"emailInput",attrs:{"rules":_vm.emailRules,"label":"E-mail","required":""},on:{"blur":_vm.checkEmail},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),(_vm.passwordResetToken === null)?_c('div',[_c('v-text-field',{attrs:{"label":"Password","append-icon":_vm.value ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.value ? 'password' : 'text',"required":""},on:{"click:append":function () { return (_vm.value = !_vm.value); }},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('br')],1):_c('div',[(
                _vm.passwordResetInput !== null &&
                  _vm.passwordResetInput !== _vm.passwordResetToken &&
                  _vm.passwordResetInput.length === 6
              )?_c('span',{staticStyle:{"font-weight":"bold","color":"red"}},[_c('small',[_vm._v("token is incorrect")])]):_vm._e(),_c('v-text-field',{attrs:{"label":"Enter Token","filled":""},on:{"change":function($event){_vm.dialog = true}},model:{value:(_vm.passwordResetInput),callback:function ($$v) {_vm.passwordResetInput=$$v},expression:"passwordResetInput"}})],1),(
              _vm.passwordResetInput === _vm.passwordResetToken &&
                _vm.passwordResetToken !== null
            )?_c('div',[_c('v-text-field',{attrs:{"rules":_vm.passwordRules,"label":"Password","append-icon":_vm.value ? 'mdi-eye' : 'mdi-eye-off',"color":"#111d5e","type":_vm.value ? 'password' : 'text',"required":""},on:{"click:append":function () { return (_vm.value = !_vm.value); }},model:{value:(_vm.passwordNew),callback:function ($$v) {_vm.passwordNew=$$v},expression:"passwordNew"}}),_c('v-text-field',{attrs:{"label":"Repeat Password","type":"password","required":""},model:{value:(_vm.repeatPassword),callback:function ($$v) {_vm.repeatPassword=$$v},expression:"repeatPassword"}})],1):_vm._e(),(_vm.emailExists && _vm.password && _vm.passwordResetToken === null)?_c('v-btn',{staticClass:"mr-4",attrs:{"text":"","color":"success"},on:{"click":_vm.login}},[_vm._v(" Login ")]):_vm._e(),(_vm.emailExists && _vm.passwordResetToken === null)?_c('v-btn',{staticClass:"mr-4",attrs:{"text":"","color":"success"},on:{"click":_vm.resetPassword}},[_vm._v(" Reset Password ")]):_vm._e(),(
              _vm.emailExists &&
                _vm.passwordResetToken !== null &&
                _vm.passwordNew === _vm.repeatPassword
            )?_c('v-btn',{staticClass:"mr-4",attrs:{"text":"","color":"success"},on:{"click":_vm.resetUser}},[_vm._v(" Proceed ")]):_vm._e(),_c('v-snackbar',{attrs:{"top":"","color":"amber"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" "),_c('v-btn',{attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v(" Close ")])],1),_c('br'),_c('br')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }